



import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import { mixins } from "vue-class-component";
import stores, { posSessionConfig } from "@/store/store";
const store: any = stores;
import Income from "@/components/urla/los/income/Income.vue";
import Expense from "@/components/urla/los/expenses/Expense.vue";
import Asset from "@/components/urla/los/assets/Asset.vue";
import RealEstateOwned from "@/components/urla/los/real-estate-owned/RealEstateOwned.vue";
import Liabilities from "@/components/urla/los/liabilities/Liabilities.vue";
import Axios from "axios";
import { BASE_API_URL } from "../../../../config";


@Component({
  components: {
    Income,
    Expense,
    Asset,
    RealEstateOwned,
    Liabilities
  }
})
export default class additionaFinancialComponent extends mixins(QuestionnaireMixin) {
  @Prop()
  public history;
  @Prop()
  public borrowerDetails;
  public logInBorrower: any = null;
  public organizationName = "";
  public creditAuthorization = false;
  public dotLoader = false;

  public async saveComponent(ref, next, current, scope) {
    try {
      this.$snotify.clear();
      let data = await this.$refs[ref]["saveInformation"]();
      if (data && data.status) {
        if (next) {
          this.onSkip(next, current);
        } else {
          await this.handleLastQuestion();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async handleLastQuestion() {
    this.$store.state.wemloLoader = true;
    this.lastQuestionConfig.scope = "AF7";
    this.lastQuestionConfig.currentSection = "AF";
    this.lastQuestionConfig.currentQuestion = 7;
    this.lastQuestionConfig.userType = this.questionHistory.userType;
    this.lastQuestionConfig.index = this.questionHistory.index;
    let updateHistory = {
      index: this.questionHistory.indexingFlow[0],
      userType: "borrower",
      currentQuestion: 1,
      previousQuestion: 1,
      currentSection: "LP",
      answeredAll: this.$userType == "Borrower" ? true : false
    };
    let data = {
      updatedHistory: updateHistory,
      history: this.questionHistory,
      posCompleted: true
    };

    await this.sendMailOnPosCompletion();

    this.$emit("callMountFunction", data);

    if(this.$userType == 'Borrower')
      this.getRedirectRouteAfterCompletion(updateHistory.answeredAll);
    this.$store.state.wemloLoader = false;
  }

  public async getLoanOrganizationDetail() {
    try {
      this.dotLoader = true;
      let res = await Axios.get(
        BASE_API_URL + "pos/getLoanOrganizationDetail",
        {
          params: {
            loanTxnId: this.$route.query.loanTxnId
          }
        }
      );
      this.dotLoader = false;
      this.organizationName = res.data.organizationName;
      this.creditAuthorization = res.data.creditAuthorization;
    } catch (error) {
      this.dotLoader = false;
    }
  }

  public async savecreditAuthorization() {
    try {
      this.$store.state.wemloLoader = true;
      let res = await Axios.post(
        BASE_API_URL + "pos/savecreditAuthorization",
        {
          creditAuthorization: this.creditAuthorization,
          loanTxnId: this.$route.query.loanTxnId
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  /********************************************************************************************************************************************************
   *                            This function return logInBorrowerName                                                                                    *
   ********************************************************************************************************************************************************/
  getLogInBorrower() {
    if (this.borrowerDetails.borrower.userId == this.$route.query.borrowerId)
      this.logInBorrower = this.borrowerDetails.borrower;
    else
      this.logInBorrower = this.borrowerDetails.coBorrower
        ? this.borrowerDetails.coBorrower
        : this.borrowerDetails.borrower;
  }

 public async sendMailOnPosCompletion(){
   try{
     if(this.$userType=="Borrower"){
     let response = await Axios.post(
      BASE_API_URL + "authentication/notifyBrokerMloPosComplete",
      {
        loanTxnId: this.$route.query.loanTxnId,
        userId:this.$userId,
        userType:this.$userType
      }
    );
   }
   else{
     return;}
   }
   catch(error){
     console.log(error)
   }
 }
  async mounted() {
    this.questionHistory = this.history;
    this.getLogInBorrower();
    await this.getLoanOrganizationDetail();
  }
}
